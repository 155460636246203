<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <div class="row pt-4">
        <div class="col-6 text-start">
          <img src="../assets/img/footer-logo.png" alt="" class="img-fluid">
        </div>
        <div class="col-6 text-end">
          <div class="social-links">
            <a href="https://www.facebook.com/share/5nzWLVGAZaqTvX3k/?mibextid=LQQJ4d" class="facebook"><i class='bx bxl-facebook-square' ></i></a>
            <a href="https://www.instagram.com/chasingwellness.pt/" class="instagram"><i class='bx bxl-instagram-alt'></i></a>
            <a href="mailto:allie@chasingwellnesspt.com" class="mail"><i class="bx bxs-envelope"></i></a>
            <a href="tel:417-233-4331" class="phone"><i class="bx bxs-phone"></i></a>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-6 text-start">
          <div class="copyright">
            &copy; Copyright <strong><span>Chasing Wellness</span></strong>.<br>All Rights Reserved
          </div>
        </div>
        <div class="col-6 text-end">
          <div class="site-links">
            <ul>
              <li><a href="https://platform.illow.io/#/policy/privacy/fd7cc2a7-a991-435b-bc30-58336c5d2b86?lng=en" target=_blank>Privacy</a></li>
              <li class="divider">|</li>
              <li><a href="https://platform.illow.io/#/policy/privacy/fd7cc2a7-a991-435b-bc30-58336c5d2b86?lng=en" target=_blank>Terms</a></li>
              <li class="divider">|</li>
              <li><a href="/sitemap.xml" target=_blank>SiteMap</a></li>
            </ul> 
          </div>
        </div>
      </div>

    </div>
  </footer><!-- End Footer -->
  <div id="preloader"></div>
  <!--<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class='bx bx-up-arrow-alt'></i></a>-->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #D7C0AE;
  color: $black;
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul>li {
    display: inline;
    list-style-type: none;
    padding: 2px; 
  }

  .divider {
    color: lighten($primary, 5);
    padding: 1px;
  }

  img {
    max-height: 110px;
  }

  h3 {
    font-size: 36px;
    font-weight: 700;
    color: $white;
    position: relative;
    font-family: $font-secondary;
    padding: 0;
    margin: 0 0 15px 0;
  }

  p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
  }

  .social-links {
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    a {
      font-size: 27px;
      display: inline-block;
      //background: $primary;
      color: $primary;
      line-height: 1;
      padding: 8px 0;
      margin: 4px;
     // border-radius: 50%;
      text-align: center;
      //width: 36px;
      height: 36px;
      //transition: 0.3s;

      &:hover {
        //background: lighten($primary, 5);
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .copyright {
    font-size: 11px;
  }

  .site-links {
    font-size: 13px;
  }

  .credits {
    font-size: 13px;

    a {
      color: lighten($primary, 5);
      transition: 0.3s;

      &:hover {
        color: $primary;
      }
    }
  }
}
</style>