<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex flex-column justify-content-center">
    <div class="container">
      <div id="learnmore" class="logo">
        <p>LEARN MORE</p>
        <a href="#about" class="scrollto scroll-down-link scroll-down-arrow" ></a>
      </div>
    </div>
  </section><!-- End Hero -->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: url("../../assets/img/hero-bg.jpg") top center;
  background-size: cover;
  position: relative;
  padding-bottom: 80px;

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .container {
    padding-top: 72px;
    position: relative;
    text-align: center;
    height: 100%;
  }

  h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
  }

  h2 {
    color: #eee;
    margin-bottom: 40px;
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    background-attachment: fixed;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 28px;
      line-height: 36px;
    }

    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }

}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1
  }

  100% {
    transform: scale(1, 1);
    opacity: 0
  }
}

#hero::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 80%;
  background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 80%,rgba(0,0,0,.8) 100%);
  background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 80%,rgba(0,0,0,.8) 100%);
}
#hero h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font : normal 300 64px/1 'Josefin Sans', sans-serif;
  text-align: center;
  white-space: nowrap;
}

#learnmore {
  width: 100%;

  p{
    width: 100%;
    position: absolute;
    bottom: 85px;
    color: white;
    z-index: 100;
    margin: 0px 0px 0px -9px;
    font-size: 13px;
  }
}



.scroll-down-arrow {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}


.scroll-down-link {
  position: absolute;
  z-index: 100;
  cursor:pointer;
  height: 60px;
  width: 50px;
  margin: 0px 0 0 -25px;
  line-height: 60px;
  bottom: 20px;
  color: $primary;
  text-align: center;
  font-size: 70px;
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  -webkit-animation: fade_move_down 2s ease-in-out infinite;
  -moz-animation:    fade_move_down 2s ease-in-out infinite;
  animation:         fade_move_down 2s ease-in-out infinite;
  /*animated scroll arrow animation*/
  @-webkit-keyframes fade_move_down {
    0%   { -webkit-transform:translate(0,-20px); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { -webkit-transform:translate(0,20px); opacity: 0; }
  }
  @-moz-keyframes fade_move_down {
    0%   { -moz-transform:translate(0,-20px); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { -moz-transform:translate(0,20px); opacity: 0; }
  }
  @keyframes fade_move_down {
    0%   { transform:translate(0,-20px); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { transform:translate(0,20px); opacity: 0; }
  }
}
</style>